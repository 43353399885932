const Evento = ({ descount }) => {
  if (descount) {
    return (
      <a
        href="/entrada/evento-evento"
        title="Ir al evento"
        className="d-block py-2"
      >
        <div
          className="w-100 box-evento-hover border overflow-hidden"
          style={{ borderRadius: "0px" }}
        >
          <div className="box-evento text-center">
            <div className="">
              <div className="text-white cardOffer">
                <span className="d-block GothamBold fs-5">25</span>
                <span className="d-block GothamBold fs-7 text-white">
                  Abril
                </span>
              </div>

              <img src="/assets/img/img-teatro-01.png" width="100%" alt="" />
            </div>

            <div
              className="w-100 text-white bg-naranja"
              style={{ borderRadius: "0px 20px 20px 20px" }}
            >
              TEATRO
            </div>

            <div className="text-start px-3 py-2">
              <h1 className="GothamBold fs-6  text-dark fs-6">
                Mi nombre es Eva Duarte Mi nombre es Eva Duarte{" "}
              </h1>
              <div className="precio fw-bold fs-4  mt-2 text-dark">
                <span
                  className="text-decoration-line-through text-muted me-2"
                  style={{ fontSize: "13px" }}
                >
                  $10000
                </span>
                $8000
              </div>
              <span className="d-block text-dark" style={{ fontSize: "12px" }}>
                Lunes 25 de abril | 21Hrs
              </span>
              <span
                className="text-dark w-100 text-start"
                style={{ fontSize: "12px" }}
              >
                El Cuenco Teatro | Córdoba - Córdoba
              </span>
            </div>
          </div>
        </div>
      </a>
    );
  }

  return (
    <a href="/entrada/evento-evento" title="Ir al evento">
      <div
        className="w-100 box-evento-hover border overflow-hidden"
        style={{ borderRadius: "0px" }}
      >
        <div className="box-evento">
          <div className="">
            <div className="text-white cardOffer">
              <span className="d-block GothamBold fs-5">25</span>
              <span className="d-block GothamBold fs-7 text-white">Abril</span>
            </div>

            <img src="/assets/img/img-teatro-01.png" width="100%" alt="" />
          </div>

          <div
            className="w-100 text-white text-center GothamBold bg-naranja"
            style={{ borderRadius: "0px 20px 20px 20px" }}
          >
            TEATRO
          </div>

          <div className="text-start px-3 py-2">
            <h1 className="GothamBold fs-6  text-dark fs-6">
              Mi nombre es Eva Duarte Mi nombre es Eva Duarte{" "}
            </h1>
            <div className="precio fw-bold fs-4  mt-2 text-dark">$8000</div>
            <span className="d-block text-dark" style={{ fontSize: "12px" }}>
              Lunes 25 de abril | 21Hrs
            </span>
            <span
              className="text-dark w-100 text-start"
              style={{ fontSize: "12px" }}
            >
              El Cuenco Teatro | Córdoba - Córdoba
            </span>
          </div>
        </div>
      </div>
    </a>
  );
};

export default Evento;
